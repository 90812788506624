<!-- 学生选科管理 -- 学科底线配置-- 列表 -->
<template>
  <div>
    <list-template
        :loading="loading"
        hasAdd
        @onAdd='$router.push("./add")'
        :search-config="searchConfig"
        :is-check-input-box="false"
        @onSearch="onSearch"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        :current-page="page"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
    ></list-template>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      search: {},
      searchConfig: [
        {
          prop: 'operation',
          placeholder: '搜索运营位'
        },
        {
          prop: 'school_id',
          tag: "select",
          placeholder: '筛选报名学校',
          options: [],
          label: "school_name",
          value: "id"
        },
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,

      tableConfig: [
        {width: "80rem", label: '序号', type: 'number'},
        {prop: 'operation_name', label: '运营位名称'},
        {prop: 'school_name', label: '校区'},
        {prop: 'creator_name', label: '创建人'},
        {
          prop: 'handle',
          label: '操作',
          width: '120rem',
          handle: true,
          buttons:(row) => row.options === 'edit' ? [{ type:"edit", text:"编辑" }] : [{ type:"view", text:"查看" }],
        }
      ]
    }
  },
  created() {
    this.$store.commit('setPage', 1)
    this.getData()
    this.getSchool()
  },
  activated() {
    this.getData()
    this.getSchool()
  },
  computed: {
    ...mapState(['page'])
  },
  methods: {
    getSchool() {
      this.$_axios2('api/pre-course-select/options/schools').then(res => {
        this.searchConfig[1].options = res.data.data || []
      })
    },

    getData() {
      this.loading = true
      let params = {...this.search, page: this.page}
      this.tableData = []
      this.$_axios2('api/pre-course-select/pc/score-baseline-settings', {params})
          .then(res => {
            let data = res.data.data
            this.tableData = data.list
            this.total = data.page.total
          })
          .finally(() => {
            this.loading = false
          })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData(val)
    },

    handleEdit(row, handle) {
      const { type } = handle
      if(type === 'edit') {
        this.$router.push('./edit?id=' + row.id)
      } else if(type === 'view') {
        this.$router.push('./details?id=' + row.id)
      }
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit('setPage', val)
      //拉取数据
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.role-list {
  padding-top: 24rem;
}

.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}

.table {
  margin-left: 32rem;
}

.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}

.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
